.PartenairesContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 3rem 10rem 3rem 10rem;
}

.PartenaireContainer {
  width: 22vw;
  height: 28vw;
  box-shadow: -10px 10px 50px rgba(0, 0, 0, 0.075);
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: column;
  text-align: center;
}

.PartenaireContainer h6 {
  margin: 2rem 2.5rem 2rem 2.5rem;
  line-height: 1.2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.LogoPartenaire {
  width: 15vw;
  height: 7.5vw;
  flex-shrink: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 2.5rem;
  padding: 0;
}
