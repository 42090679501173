.carouselContainer,
.carouselContainer div {
  width: 50vw;
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: saturate(0);
  -webkit-backdrop-filter: saturate(0);
}

.carouselContainer h5 {
  margin: 0 5rem;
}

.carousel .slider-wrapper {
  height: 45vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.carousel .thumbs-wrapper {
  display: none;
}

.carousel .control-dots .dot {
  box-shadow: 0 0 0 transparent;
  background-color: white;
}

.carousel .carousel-status {
  display: none;
}

.carousel button {
  margin: 0 20px;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 0.5;
}

.carousel.carousel-slider .control-arrow:hover {
  background-color: transparent;
  opacity: 1;
}
