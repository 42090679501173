.NavBar {
  width: 100vw;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  color: #2b2b2b;
  height: 65px;
}

.NavBarLinksContainer {
  width: 50vw;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.NavBarLink {
  font-family: "IBMPlex", monospace;
  font-weight: 400;
  font-size: 0.9rem;
  border: none;
  margin: 0 2.5rem;
  background-color: transparent;
}

.NavBarLinkAdmin {
  font-family: "IBMPlex", monospace;
  font-weight: 400;
  font-size: 0.9rem;
  border: none;
  margin: 0 1rem;
  background-color: transparent;
}

.NavBarLink:hover,
.NavBarLinkAdmin:hover,
.active {
  border-bottom: solid 1px #5aff6e;
  cursor: pointer;
  color: #5aff6e;
}

.Logo {
  width: 46vw;
  display: flex;
  align-items: center;
}

.HomeLink {
  cursor: pointer;
  font-size: 1rem;
  background-color: transparent;
  width: 75px;
}
