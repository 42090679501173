@media all and (min-width: 1500px) {
  h1 {
    font-size: 2.25rem;
    padding: 0 9rem;
    line-height: 3.75rem;
  }

  h4 {
    margin: 0 25rem;
  }

  p {
    margin: 3rem 25rem;
  }

  .RedirectingBlockToAllArticles img {
    width: 100px;
  }

  .ModaleBlockPreview h4,
  .ModaleBlockPreview p {
    margin: 2.5rem 10rem 0 10rem;
  }

  .ModaleBlockPreview h2 {
    margin: 5rem 10rem 0 10rem;
  }

  .ModaleBlockPreview h3 {
    margin: 1rem 10rem 0 10rem;
  }

  .ModaleBlockPreview .smallLink {
    margin: 1rem 10rem 5rem 10rem;
  }

  .ModaleBlockPreview .quote {
    padding: 3rem 15rem;
  }

  .headerAtelier h2 {
    font-size: 3rem;
    line-height: 4.25rem;
    padding: 17.5vh 7.5rem 0 7.5rem;
  }

  .anchorLinksContainer {
    padding-bottom: 10vh;
  }

  .EquipeMembersContainer {
    margin: 3rem 20rem 3rem 25rem;
  }

  .memberContainer {
    width: 25vw;
  }

  .prevMembersContainer {
    width: 34vw;
  }

  .PartenairesContainer {
    margin: 3rem 15rem 3rem 25rem;
  }

  .PartenaireContainer {
    width: 18vw;
    height: 25vw;
  }

  /* MODALE */
  .ModaleBlock h2,
  .ModaleBlock h3,
  .ModaleBlock .smallLink {
    padding: 0 10rem;
  }

  .ModaleBlock .quote {
    padding: 3rem 20rem;
  }
}

@media all and (min-width: 1100px) and (max-width: 1150px) {
  .PartenaireContainer h6 {
    -webkit-line-clamp: 6;
  }
}

@media all and (min-width: 1000px) and (max-width: 1100px) {
  .PartenaireContainer h6 {
    -webkit-line-clamp: 5;
  }
}

@media all and (max-width: 1000px) {
  .PartenaireContainer h6 {
    -webkit-line-clamp: 5;
  }
}

@media all and (max-width: 980px) {
  /* GENERAL */

  .spacer {
    height: 130px;
  }

  .NavBar {
    flex-wrap: wrap;
    height: 130px;
  }

  .NavBar .Logo {
    box-shadow: 0 0 100px rgba(0, 0, 0, 0.045);
    height: 65px;
    justify-content: center;
    width: 100vw;
  }

  .NavBar .HomeLink {
    margin-left: 0;
    padding: 0;
  }

  .NavBarLinksContainer {
    width: 100vw;
    padding: 0 3rem;
    height: 65px;
  }

  .quote {
    padding: 3rem 0 3rem 3rem;
  }

  /* HOMEPAGE */

  .IntroBlockRight,
  .LogoBlock {
    height: 50vh;
    width: 100vw;
    padding: 0;
  }

  .LogoImg {
    width: 30vw;
  }

  .IntroBlockRight {
    height: auto;
    width: 100vw;
    padding: 5rem 0;
  }

  .IntroBlockLeft {
    width: 100vw;
    height: 100vh;
  }

  .IntroBlocks {
    display: block;
  }

  .carouselContainer,
  .carouselContainer div {
    width: 100vw;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: saturate(0);
    -webkit-backdrop-filter: saturate(0);
  }

  .carousel .slider-wrapper {
    height: 50vh;
  }

  /* ARTICLES THUMBNAILS */

  .ArticleThumbnailClassic,
  .ArticleThumbnailHasImage,
  .RedirectingBlockToAllArticles {
    width: 50vw;
    height: 50vw;
  }

  .ArticleThumbnailClassic > *,
  .ArticleThumbnailHasImage > *,
  .RedirectingBlockToAllArticles > * {
    width: 50vw;
  }

  .RedirectingBlockToAllArticles img {
    width: 100px;
  }

  /* PAGE ATELIER */

  .AtelierSection h4 {
    margin: 0 7rem;
  }

  .AtelierSection p {
    margin: 3rem 7rem;
  }

  .AtelierSection .quote {
    padding: 2rem 5rem;
  }

  .headerAtelier h2 {
    padding-top: 13vh;
    margin: 0 10rem;
  }

  .anchorLinksContainer {
    flex-wrap: wrap;
  }

  .headerAtelier .smallLink {
    width: 33.3vw;
    text-align: center;
    margin-top: 10px;
  }

  .EquipeMembersContainer {
    margin: 3rem 10rem 3rem 10rem;
  }

  .memberContainer {
    width: 100vw;
  }

  .memberPic {
    width: 15vw;
    height: 15vw;
  }

  .PartenairesContainer {
    margin: 3rem 5rem 3rem 10rem;
  }

  .PartenaireContainer {
    width: 30vw;
    height: 35vw;
    box-shadow: -10px 10px 50px rgba(0, 0, 0, 0.075);
  }

  div.LogoPartenaire {
    width: 18vw;
    height: 10vw;
  }

  .thxLink {
    width: 100%;
  }

  /* PAGES PROJETS & LAB */

  .SectionTitleBlock {
    width: 100vw;
    height: 40vw;
  }

  /* MODALE */

  .ModaleBlock h2,
  .ModaleBlock h3,
  .ModaleBlock .smallLink {
    margin: 8rem 8rem 0 10rem;
  }

  .ModaleBlock h3,
  .ModaleBlock .smallLink {
    margin: 1.5rem 8rem 0 10rem;
  }

  .ModaleBlock .smallLink {
    margin-bottom: 8rem;
  }

  .ModaleBlock .quote {
    padding: 3rem 15rem;
  }

  .ModaleBlock img {
    width: 75vw;
  }

  .recapBlockFixed {
    width: calc(100vw);
    height: auto;
    padding: 3rem 5rem 3rem 10rem;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .contentBlock {
    display: block;
  }

  .contentBlock .recapBlockFixed h6 {
    line-height: 1.3rem;
    width: 35vw;
  }

  .recapBlockFixed h6.recapBlockTitle {
    margin: 0 0 1rem 0;
    width: 100vw;
    line-height: 1.4rem;
  }

  .content {
    width: 100vw;
  }
}

@media all and (min-width: 900px) and (max-width: 980px) {
  .PartenaireContainer h6 {
    -webkit-line-clamp: 7;
  }
}

@media all and (max-width: 900px) {
  .PartenaireContainer h6 {
    -webkit-line-clamp: 6;
  }
}

@media all and (max-width: 850px) {
  .PartenaireContainer h6 {
    -webkit-line-clamp: 5;
  }
}

@media all and (max-width: 750px) {
  /* FOOTER */

  .Footer {
    flex-direction: column;
    height: auto;
    padding: 1.5rem 0;
  }

  .Footer > div {
    width: 100vw;
    text-align: center;
    padding: 0.5rem;
  }

  .headerAtelier h2 {
    font-size: 2rem;
    margin: 0 8rem;
    line-height: 3rem;
  }

  /* PAGE ATELIER */

  .PartenaireContainer h6 {
    -webkit-line-clamp: 4;
  }

  /* PAGES PROJETS & LAB */

  .Filter {
    width: 50vw !important;
  }

  .FilterTitle {
    margin: 0;
  }

  .NavBarLink {
    margin: 0 1rem;
  }

  .FiltersSection {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* MODALE */

  .recapBlockFixed {
    position: relative;
  }
}

@media all and (max-width: 800px) {
  .ModaleBlock .quote {
    padding: 3rem 9.5rem;
  }
}

@media all and (max-width: 500px) {
  /* HOMEPAGE */

  .LogoImg {
    width: 50vw;
  }

  h1 {
    font-size: 1.45rem;
    padding: 0 3.5rem;
    line-height: 2.5rem;
  }

  /* ARTICLES THUMBNAILS */

  .ArticleThumbnailClassic,
  .ArticleThumbnailHasImage,
  .RedirectingBlockToAllArticles {
    width: 100vw;
    height: 50vh;
  }

  .ArticleThumbnailClassic > *,
  .RedirectingBlockToAllArticles > *,
  .ArticleThumbnailHasImage > * {
    width: 100vw;
  }

  .RedirectingBlockToAllArticles img {
    width: 100px;
  }

  /* PAGE ATELIER */

  .AtelierSection:nth-child(6) {
    padding-bottom: 0;
  }

  .AtelierSection h4 {
    margin: 0 3rem;
  }

  .AtelierSection p {
    margin: 3rem;
  }

  .AtelierSection .quote {
    padding: 0 2rem;
  }

  .headerAtelier {
    height: auto !important;
  }

  .headerAtelier h2 {
    padding: 9vh 0;
    margin: 0 4.5rem;
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  .anchorLinksContainer {
    flex-wrap: wrap;
  }

  .headerAtelier .smallLink {
    width: 100vw;
    text-align: center;
    margin-top: 10px;
  }

  .ModaleBlock h4 {
    margin: 0 3rem;
  }

  .ModaleBlock p {
    margin: 3rem 3rem;
    font-size: 1.15rem;
  }

  .ModaleBlock img {
    width: 100vw;
  }

  .EquipeMembersContainer {
    margin: 3rem 0 0 0;
  }

  .memberPic {
    width: 30vw;
    height: 30vw;
    margin-top: 10vh;
  }

  .PartenairesContainer {
    margin: 2rem 4rem;
  }

  .PartenaireContainer {
    width: 60vw;
    height: 75vw;
    box-shadow: 0 10px 50px rgba(0, 0, 0, 0.075);
  }

  .LogoPartenaire {
    width: 30vw;
  }

  img.LogoPartenaire {
    max-width: 30vw;
  }

  /* PAGES PROJETS & LAB */

  .SectionTitleBlock {
    width: 100vw;
    height: 60vw;
  }

  /* MODALE */

  .ModaleBlock h2,
  .ModaleBlock h3,
  .ModaleBlock .smallLink {
    margin: 1rem 3rem 0 3rem;
  }

  .ModaleBlock .quote {
    padding: 1.5rem 3rem;
  }

  .recapBlockFixed {
    width: 100vw;
    padding: 2.5rem 0;
  }

  .contentBlock .recapBlockFixed h6 {
    margin: 0.4rem 3rem;
    width: 100vw;
  }

  .articleSuggestion h4 {
    margin: 0 0 4rem 3rem;
  }
}
