.IntroBlocks {
  display: flex;
  flex-wrap: wrap;
}

.IntroBlockLeft {
  width: 50vw;
  height: 90vh;
  z-index: 2000;
}

.CarrouselBlock {
  height: 45vh;
  background-color: #2b2b2b;
}

.IntroBlockRight {
  width: 50vw;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogoBlock {
  width: 50vw;
  height: 45vh;
  background-color: #fbd052;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogoImg {
  width: 20vw;
}
