.SectionTitleBlock {
  width: 50vw;
  height: 25vw;
}

.SectionPic {
  background: no-repeat url("../img/rusch-pic-articles.png");
  background-size: 90%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
