.Contact .wrapper {
  padding: 65px 85px;
}

.Contact img {
  width: 2rem;
  margin-right: 1rem;
  filter: invert(100%);
}

.Contact img:hover {
  filter: invert(0%);
}

.categories {
  display: flex;
  justify-content: space-between;
}

.categories h4 {
  margin: 0;
  padding: 0;
  padding-bottom: 2.5rem;
}

.category {
  width: 45%;
  white-space: pre-wrap;
  display: flex;
  flex-direction: column;
}

.category .items {
  display: flex;
}

.category .items .item {
  width: 50%;
  margin-right: 50px;
}

.category .items .contact:last-child {
  margin-right: 0;
}

.category .items .item .name {
  font-weight: bold;
  text-decoration: underline;
  margin-bottom: 10px;
}

.reseaux {
  display: flex;
  margin-top: 2rem;
  margin-left: -10px;
}

.Contact h2 {
  margin: 0;
  margin-bottom: 50px;
}

.Contact .currentText {
  line-height: 1.75rem;
}

.Contact .iframes {
  display: flex;
  width: 100%;
}

.Contact .iframes iframe {
  width: 50%;
  min-height: 50vh;
}

@media all and (max-width: 1000px) {
  .Contact .wrapper {
    padding: 50px 65px;
  }

  .categories {
    display: block;
  }

  .category {
    width: 100%;
  }

  .category:first-child {
    margin-bottom: 70px;
  }

  .category .items .item:last-child {
    margin-right: 0;
  }
}

@media all and (max-width: 500px) {
  .Contact .wrapper {
    padding: 40px 55px;
  }

  .Contact h2 {
    margin-bottom: 40px;
  }

  .category:first-child {
    margin-bottom: 25px;
  }

  .categories h4 {
    padding-bottom: 1.5rem;
  }

  .category .items {
    display: block;
  }

  .category .items .item {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .reseaux {
    margin-top: 1rem;
  }

  .Contact .iframes {
    flex-direction: column;
  }

  .Contact .iframes iframe {
    width: 100%;
    min-height: 40vh;
  }
}
