form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
}

form button {
  padding: 0.8rem;
  margin: 0.3rem;
}

.box {
  display: flex;
  flex-direction: row;
  width: 100vw;
}

.item-left,
.item-right {
  width: 50vw;
  position: fixed;
  top: 0;
  overflow: scroll;
  background-color: white;
  margin-top: 65px;
  height: calc(100vh - 65px);
}

.fullScreenBanner {
  position: fixed;
  z-index: 6000;
  background: hsl(0, 0%, 15%);
  color: hsl(0, 0%, 85%);
  padding: 0 1rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.75);
  height: 2.75rem;
  display: flex;
  align-items: center;
  font-family: 'IBMPlex', sans-serif;
  font-size: 0.9rem;
  cursor: pointer;
}

.fullScreenBanner:hover,
.fullScreenBanner:hover .fullScreenArrow {
  color: white;
}

.fullScreenArrow {
  color: hsl(0, 0%, 85%);
  margin-right: 13px;
}

.fullScreenPlaceholder {
  height: 2.75rem;
  width: 100%;
}

input,
.DraftEditor-root {
  width: 35vw;
  margin-bottom: 30px;
  white-space: pre-line;
}

input {
  height: 35px;
  margin-top: 10px;
  border: solid 1px darkgrey;
  padding: 0 10px;
}

.DraftEditor-root {
  min-height: 35px;
  font-family: 'IBMPlex', sans-serif;
  font-size: 0.8rem;
  min-height: 10vh;
  border-bottom: solid 1px darkgrey;
  border-right: solid 1px darkgrey;
  border-left: solid 1px darkgrey;
}

.DraftEditor-editorContainer,
.public-DraftEditor-content {
  padding: 5px 7px 7px 8px;
  min-height: 10vh;
}

.text-editor-toolbar {
  border-top: solid 1px darkgrey;
  border-right: solid 1px darkgrey;
  border-left: solid 1px darkgrey;
  width: 35vw;
  padding-top: 7px;
  padding-bottom: 2px;
  padding-left: 9px;
}

.draftBtn {
  margin: 0 5px 0 0;
  width: 25px;
  height: 25px;
  padding: 5px;
  cursor: pointer;
  font-size: 0.85rem;
}

.underline {
  text-decoration: underline;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.url {
  margin-left: 4px;
}

textarea {
  height: 12vh;
  max-width: 35vw;
  min-width: 35vw;
  margin-bottom: 30px;
  margin-top: 10px;
  white-space: pre-line;
  padding: 8px 10px;
  font-family: 'IBMPlex', sans-serif;
}

input:focus,
textarea:focus {
  outline: none;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

select {
  height: 35px;
  margin-top: 10px;
  width: 35vw;
  white-space: pre-line;
  margin-bottom: 30px;
  border-radius: 0;
  outline: none;
}

label {
  width: 35vw;
  font-family: 'IBMPlex', monospace;
  display: flex;
  flex-wrap: wrap;
}

.labelContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

input[type='submit'],
.draft {
  cursor: pointer;
  height: 35px;
  background-color: #f9f9f9;
  border-radius: 5px;
  font-family: 'IBMPlex', monospace;
  font-size: 1rem;
  margin-bottom: 5rem;
}

input[type='submit'].submit:hover {
  background-color: #2b2b2b;
  color: white;
}

input[type='submit'].draft {
  margin: 0.6rem 0 0.25rem 0;
}

input[type='submit'].draft:hover {
  opacity: 1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.addModule {
  font-family: 'IBMPlex', sans-serif;
  font-size: 1.5rem;
  margin: 3.5rem 0 2rem 0;
  width: 35vw;
}

.removeModule {
  font-size: 0.8rem;
  cursor: pointer;
  margin: 0 0 0 6px;
  align-self: flex-end;
  opacity: 0.35;
  padding: 0;
  z-index: 300;
}

.removeModule:hover {
  opacity: 1;
}

.draggableElement {
  display: flex;
  justify-content: space-between;
}

.moveCursor {
  width: 33vw;
  cursor: move;
}

.opacity-ghost {
  opacity: 0.95;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  background-color: white;
  border-radius: 4px;
  width: 115%;
  padding: 5% 7.5% 1% 7.5%;
  margin-left: 50%;
  transform: translateX(-50%);
}

.DynamicInputs {
  display: flex;
  flex-direction: column;
}

#buttons {
  display: flex;
  flex-direction: column;
}

#buttons input {
  background-color: #fbd052;
  border: none;
  margin-bottom: 10px;
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  transition: 0.1s ease-in;
  cursor: pointer;
}

#buttons input:hover {
  box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.3);
  transition: 0.1s ease-in;
}

.TagCardsContainer {
  display: flex;
  width: 35vw;
  flex-wrap: wrap;
  margin: 5px 0 35px 0;
}

.TagCard {
  border: solid 1px darkgrey;
  border-radius: 4px;
  padding: 7px 15px;
  margin: 10px 10px 0 0;
  font-family: 'HeimatMono', monospace;
  font-size: 0.7rem;
  text-align: center;
  width: auto;
}

.TagCard:hover {
  color: white;
  background-color: #2b2b2b;
  border-color: transparent;
}

.TagCardSelected {
  color: white;
  background-color: #2b2b2b;
  border-color: transparent;
}

.TagCardDraft {
  border: solid 1px #fbd052;
  background-color: #fbd052;
  border-radius: 4px;
  padding: 7px 15px;
  margin: 10px 10px 0 0;
  font-family: 'HeimatMono', monospace;
  font-size: 0.7rem;
  text-align: center;
  width: auto;
  cursor: default;
}

.errorPost {
  font-size: 0.7rem;
  font-family: 'HeimatMono', monospace;
  color: red;
  margin: -3rem 0 3rem 0;
}

.Footer {
  display: none;
}

.text-editor-toolbar {
  display: flex;
}

.editor-link,
.editor-link * {
  color: #fbd052;
  border-bottom: solid 1px #fbd052;
}

.editor-url-input {
  display: flex;
  justify-content: space-between;
  width: 35vw;
  align-items: baseline;
}

.editor-url-input button {
  width: auto;
  padding: 0.55rem 0.7rem;
  background: #fbd052;
  margin: 0 0 0 0.6rem;
}

.icon-hypertext {
  fill: none;
  stroke: #000;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 9px;
}
