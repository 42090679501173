/* General Items */
* {
  margin: 0;
  padding: 0;
  color: #2b2b2b;
  transition: background-color 0.2s ease-out, opacity 0.2s ease-out,
    color 0.2s ease-out, filter 0.1s ease-out, box-shadow 0.2s ease-out;
}

*:hover {
  transition: background-color 0.2s ease-in, opacity 0.2s ease-in,
    color 0.2s ease-in, filter 0.1s ease-in, box-shadow 0.2s ease-in;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}

button {
  border: none;
  outline: 0;
  background-color: transparent;
}

.spacer {
  width: 100vw;
  height: 65px;
}

.FilterBlack {
  background-color: rgba(0, 0, 0, 0.5);
}

a {
  text-decoration: none;
}

[tabindex] {
  outline: 0;
  width: 100vw;
}

.loading {
  font-family: 'HeimatMono', monospace;
  font-size: 1rem;
  text-align: center;
  width: 100%;
  height: 100%;
  margin-top: 5rem;
}

/* TYPEFACES */

@font-face {
  font-family: 'HeimatMono';
  src: url('./fonts/HeimatMono.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'HeimatMono';
  src: url('./fonts/HeimatMonoExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'IBMPlex';
  src: url('./fonts/IBMPlexSansRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'IBMPlex';
  src: url('./fonts/IBMPlexSansSemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

p {
  font-family: 'IBMPlex', sans-serif;
  font-weight: 400;
  margin: 2rem 10rem;
  line-height: 2rem;
  font-size: 1.2rem;
  white-space: pre-wrap;
}

h1 {
  font-family: 'HeimatMono', monospace;
  font-weight: 200;
  font-size: 1.6rem;
  padding: 0 5rem 0 5rem;
  line-height: 3.1rem;
}

h2 {
  /* PAGE ATELIER HEADER = CHAPEAU SUR L'IMAGE EN BACKGROUND */

  /* PAGE MODALE = TITLE */
  font-family: 'HeimatMono', monospace;
  font-weight: 200;
  font-size: 2.5rem;
  margin: 0 14rem 0 15rem;
  line-height: 3.5rem;
}

h3 {
  /* PAGE MODALE = SHORT DESCRIPTION */
  font-family: 'HeimatMono', monospace;
  font-weight: 200;
  font-size: 1.5rem;
  margin: 1.15rem 14rem 0 15rem;
  line-height: 2.25rem;
  color: white;
}

h4 {
  /* PAGE MODALE = PARAGRAPH TITLE */
  font-family: 'IBMPlex', sans-serif;
  font-weight: 600;
  margin: 0 10rem;
  padding-top: 5rem;
  font-size: 2rem;
  align-self: flex-start;
}

h5 {
  font-family: 'HeimatMono', monospace;
  font-weight: 800;
  font-size: 0.85rem;
  text-transform: uppercase;
  letter-spacing: 0.25rem;
  line-height: 1.5rem;
  margin: 0 2rem;
  transition: 0.2s ease-in;
}

h6 {
  font-family: 'HeimatMono', monospace;
  font-weight: 200;
  font-size: 0.85rem;
}

.currentText {
  font-family: 'IBMPlex', sans-serif;
  font-weight: 200;
}

.bigLink {
  padding-bottom: 1px;
  border-bottom: solid 2px #5aff6e;
  cursor: pointer;
}

.smallLink {
  font-family: 'IBMPlex', sans-serif;
  font-weight: 200;
  font-size: 1rem;
  color: #fbd052;
  text-decoration: underline;
  margin: 1.15rem 15rem 0 15rem;
  cursor: pointer;
}

.quote {
  /* Quotes in yellow and big size : on modale page and atelier page */
  font-family: 'HeimatMono', monospace;
  font-weight: 200;
  letter-spacing: 2px;
  font-size: 2.25rem;
  line-height: 3rem;
  padding: 3rem 15rem;
  color: #fbd052;
  white-space: pre-line;
  word-break: break-word;
  hyphens: auto;
}

.caption {
  font-family: 'HeimatMono', monospace;
  font-weight: 200;
  font-size: 0.75rem;
  margin-top: 1rem;
  font-style: oblique;
  line-height: 1.25rem;
}

p.abstract {
  font-style: oblique;
  opacity: 0.75;
  margin-top: 5rem;
}

.AdminTitles {
  font-family: 'IBMPlex', sans-serif;
  font-size: 1.5rem;
  margin: 7vh 0 2.5vh 0;
  width: 100vw;
}

/* Colors */

.yellow {
  color: #fbd052;
}

.darkgrey {
  color: #2b2b2b;
}

.green {
  color: #5aff6e;
}

/* Atomic classes */

.capitalize:first-letter {
  text-transform: uppercase;
}
