.memberPic {
  border-radius: 50%;
  width: 10vw;
  height: 10vw;
  margin-top: 5vw;
  filter: grayscale(100%);
  background-size: cover;
}

.memberContainer {
  box-shadow: -10px 7px 50px rgba(0, 0, 0, 0.075);
  width: 30vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.memberContainer h6 {
  text-align: center;
  padding: 0.5rem 3rem;
  line-height: 1.25rem;
}

.memberName {
  font-family: 'HeimatMono', monospace;
  font-size: 1.25rem;
  padding-top: 2.5rem;
  width: 100%;
  text-align: center;
}

.memberContent {
  font-family: 'IBMPlex', sans-serif;
  font-size: 0.85rem;
  line-height: 1.25rem;
  padding: 1rem 3rem 0 3rem;
  white-space: pre-wrap;
}

.EquipeMembersContainer {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 3rem 10rem 3rem 15rem;
}

.smallLinksContainer {
  padding: 2rem 3rem 4.5rem 3rem;
  align-self: flex-start;
}

.EquipeMembersContainer .smallLink {
  margin: 0 4.5rem 0 0;
}

.w100p {
  width: 100%;
}

.thxText {
  font-family: 'HeimatMono', monospace;
  font-size: 1.9rem;
  line-height: 2.75rem;
  color: #fbd052;
  font-weight: 600;
  margin-top: 100px;
}

.thxLinksContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 0;
  padding-top: 2.5rem;
  width: 100%;
}

.thxLink {
  margin: 0.5rem;
  font-family: 'IBMPlex', sans-serif;
  font-weight: 200;
  font-size: 1rem;
  color: #fbd052;
  text-decoration: underline;
  cursor: pointer;
  width: 20%;
  min-width: 200px;
}

.prevMembersContainer {
  width: 44vw;
}

.bgWhite {
  background-color: white;
}

.p3vw {
  padding: 4.5vw 8vw 4vw 8vw;
}
