/* ADMIN WELCOME PAGE */

.AdminHomeWelcome {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  padding: 5rem;
  text-align: center;
  font-size: 5rem;
}

.AdminHomeWelcome p {
  font-size: 1.5rem;
  line-height: 2.5rem;
}

.AdminHomeContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}

.AdminHomeLinkTo {
  width: 20vw;
  height: 20vw;
  background-color: #fbd052;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 100px rgba(0, 0, 0, 0.05);
  align-items: center;
  font-family: 'HeimatMono', monospace;
  font-size: 1.5rem;
}

.AdminHomeLinkTo:hover {
  text-decoration: underline;
}

/* BUTTONS */

button {
  padding: 5px;
  margin-left: 20px;
  width: 20px;
}

.ButtonCreateElement {
  display: flex;
  justify-content: center;
  width: 70vw;
  padding: 7vh 2vw;
  border: solid 1px #5aff6e;
  background-color: transparent;
  font-family: 'HeimatMono', monospace;
  font-size: 1.5rem;
  color: #5aff6e;
}

.ButtonCreateElement:hover {
  background-color: #5aff6e;
  color: white;
}

.ReactIconAdd {
  fill: #5aff6e;
  margin-right: 20px;
  cursor: pointer;
}

.ButtonCreateElement:hover .ReactIconAdd {
  fill: white;
}

.formTitle {
  font-family: 'IBMPlex', sans-serif;
  font-size: 1.5rem;
  margin-bottom: 2.5rem;
  text-align: left;
  width: 35vw;
}

.star {
  cursor: default;
  border-right: solid 1px rgba(0, 0, 0, 0.15);
  padding-right: 2.5rem;
  margin-top: 2px;
}

.hasStar {
  font-size: 1rem;
  color: #fbd052;
}

.hasNoStar {
  color: lightgrey;
  cursor: default;
  font-size: 1rem;
  z-index: 2000;
}

.ReactIcon {
  font-size: 1rem;
  opacity: 0.65;
  cursor: pointer;
}

.ReactIcon:hover {
  opacity: 1;
}

.ReactIconPic {
  font-size: 1rem;
  margin-top: 3px;
  margin-left: 2px;
  color: #5aff6e;
  opacity: 0.7;
}

.inactive {
  opacity: 0.25;
}

/* CONTAINERS */

.AdminLoginLogoBlock {
  width: 48vw;
  height: 100vh;
  background-color: #5aff6e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogInForm {
  width: 52vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  justify-content: flex-start;
}

div.LogInTitle {
  text-align: center;
  margin: 9rem 0 4rem 0;
}

.GlobalContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10vh 12.5vw;
}

.AdminCardFullWidth {
  padding: 20px;
  width: 75vw;
  margin: 10px 0;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.AdminCardHalfWidth {
  padding: 20px;
  min-width: 30vw;
  max-width: 30vw;
  margin: 10px 1vw;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.AdminCardHalfWidthContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 75vw;
}

.EditButtonsContainer {
  display: flex;
  align-items: center;
}

.EditButtonsContainer h6 {
  margin-right: 100px;
  margin-top: 5px;
}

.LogOut {
  font-size: 1.4rem;
  cursor: pointer;
}

.LogOut:hover {
  fill: #5aff6e;
}

.picBtn {
  background-color: red;
  cursor: pointer;
  font-size: 1.1rem;
  padding: 0;
  margin: 0 0 0 10px;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  background-color: black;
}

.active {
  opacity: 1;
}

/* ADMIN FORMS */

.contactForms {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contactForm {
  margin-top: 0;
}

@media all and (max-width: 900px) {
  .contactForm {
    width: 100%;
  }

  .contactForm * {
    width: 100%;
    display: block;
    max-width: 100%;
  }

  .contactForm input[type='submit'] {
    /*    margin-bottom: 80px;*/
  }
}
