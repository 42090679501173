.ArticlesBlock {
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
}

.ArticleThumbnailClassic {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  box-shadow: 0 0 200px rgba(0, 0, 0, 0.075);
  width: 25vw;
  height: 25vw;
  background-color: white;
  text-align: center;
}

.ArticleThumbnailClassic > * {
  width: 25vw;
}

.ArticleThumbnailHasImage h6 {
  transition: 0.2s ease-in;
  color: white;
}

.ArticleThumbnailClassic h6 {
  transition: 0.2s ease-in;
  color: #fbd052;
  margin: 2rem 0;
}

.ArticleThumbnailClassic:hover {
  background-color: #fbd052;
}

.ArticleThumbnailClassic:hover h6 {
  transition: 0.2s ease-in;
  color: #2b2b2b;
}

.ArticleThumbnailHasImage h5 {
  transition: 0.2s ease-in;
  color: #5aff6e;
}

.ArticleThumbnailHasImage:hover h6 {
  color: white;
}

.ArticleThumbnailFilterBlack {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  align-items: center;
  text-align: center;
  height: 100%;
}

.ArticleThumbnailClassic:hover .ArticleThumbnailFilterBlack {
  background-color: rgba(0, 0, 0, 0.4);
}

.ArticleThumbnailFilterBlack > * {
  width: 25vw;
}
