.RedirectingBlockToAllArticles {
  width: 25vw;
  height: 25vw;
  background-color: #5aff6e;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

.arrow {
  width: 25vw;
  text-align: center;
  align-self: center;
}

img.arrowGif,
.ModaleBlock img.arrowGif {
  width: 100px;
  height: auto;
  text-align: center;
  align-self: center;
}

.RedirectingBlockToAllArticles h6 {
  padding-bottom: 2.5rem;
  text-align: center;
}

.RedirectingBlockToAllArticles:hover {
  background-color: #2b2b2b;
}

.RedirectingBlockToAllArticles:hover img {
  filter: invert(100%);
}

.RedirectingBlockToAllArticles:hover h6,
.RedirectingBlockToAllArticles:hover .arrow {
  color: #5aff6e;
}
