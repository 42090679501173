.ModaleBlock {
  width: 100vw;
  height: 100vh;
  z-index: 5000;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: white;
}

.ModaleBlockPreview {
  width: 50vw;
  z-index: 5000;
  top: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  box-shadow: -20px 10px 100px rgba(0, 0, 0, 0.05);
}

.ModaleHeader {
  padding: 7rem 0.75rem;
}

.closeModaleBtn {
  position: fixed;
  right: 25px;
  top: 20px;
  font-size: 2.5rem;
  background: white;
  border-radius: 50%;
  cursor: pointer;
  z-index: 5000;
  width: 65px;
  height: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ModaleBlock p {
  margin: 2rem 7rem;
}

.ModaleBlock .contentBlock .textContent a,
.ModaleBlock .contentBlock .textContent a *,
.ModaleBlockPreview .contentBlock .textContent a,
.ModaleBlockPreview .contentBlock .textContent a * {
  font-family: 'HeimatMono', monospace;
  color: #fbd052;
  padding-bottom: 1px;
  border-bottom: solid 1px #fbd052;
}

.ModaleBlock h4 {
  margin: 0 7rem;
}

.ModaleBlock .quote {
  padding: 3rem 12rem;
}

.ModaleBlock img,
.ModaleBlock iframe,
.ModaleBlockPreview img,
.ModaleBlockPreview iframe {
  width: 50vw;
  padding-top: 3rem;
}

.ModaleBlock iframe,
.ModaleBlockPreview iframe {
  min-height: 30vw;
}

.ModaleBlockPreview h2 {
  padding: 0;
  margin: 5rem 2rem 0 7rem;
}

.ModaleBlockPreview h3 {
  padding: 0;
  margin: 1rem 2rem 0 7rem;
}

.ModaleBlockPreview h4 {
  padding: 0;
  margin: 5rem 2rem 0 7rem;
}

.ModaleBlockPreview p {
  padding: 0;
  margin: 2rem 2rem 0 7rem;
}

.ModaleBlockPreview .smallLink {
  padding: 0;
  margin: 1rem 4rem 5rem 7rem;
}

.ModaleBlockPreview .quote {
  padding: 3rem 11rem;
}

.articleSuggestion {
  width: 100vw;
}

.articleSuggestion h4 {
  margin: 0 0 4rem 5rem;
}

.contentBlock {
  display: flex;
}

.recapBlockFixed {
  width: 25vw;
  background-color: #fbd052;
  height: 100vh;
  position: sticky;
  top: -1px;
  padding: 0 3rem;
  line-height: 1.4rem;
  box-shadow: 20px 10px 100px rgba(0, 0, 0, 0.05);
}

.recapBlockTitle {
  margin: 4rem 0 2.5rem 0;
  font-weight: 800;
  font-size: 1rem;
}

.ModaleBlockPreview .recapBlockFixed {
  display: none;
}

.content {
  width: 75vw;
}
